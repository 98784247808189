import React, {useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Col,
  Row,
  Form,
  Input,
  DatePicker,
  Divider,
  TimePicker,
} from "antd";
import { NavLink } from "react-router-dom";
import dayjs from "dayjs";
import "./home.css";
import HomeAbout from "../components/HomeAbout";
 import Solutions from "../components/Solutions1";
import Segment from "../components/Segment";
const Home = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <div className="page-content">
    <Helmet>
    <title>Poll Sphere is a premier market research solutions</title>
    <meta name="description" content="Poll Sphere is a professional survey platform that provides advanced tools for survey distribution and data collection using CAPI, CAWI, and CATI" />
    <link rel="canonical" href="https://www.pollsphere.com/" />
    </Helmet>
      <div className="home-banner position-relative">
        <div className="video overflow-hidden">
          <video muted loop="true" autoPlay="true" className="d-block">
            <source src="/images/home/banner.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="banner-content">
          <div className="container h-100">
            <div className="row d-flex h-100 py-50">
              <div className="col-lg-9">
                <div className="wrapper text-white ">
                  <p>
                    <span>Welcome </span>
                  </p>
                  <hr />
                  <h1>
                    Partner With <br /> The World’s Best Brands
                  </h1>

                  <NavLink to="/contact" className="btn">
                    {" "}
                    Contact Us
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-50">
        <Row gutter={24} align={"middle"}>
          <Col xs={24} md={10}>
            <div className="wrapper">
              <h2 className=" section-heading">
              Welcome to Poll Sphere: Your Gateway to Informed Decision-Making!
              </h2>

              <p className="">
              At Poll Sphere, we believe in the power of data to transform businesses. Our platform is designed to empower you with the insights you need through market research and online surveys. Navigate the ever-changing landscape of consumer preferences, industry trends, and emerging markets with confidence.
              </p>
              <p><strong>Join the Poll Sphere Community:</strong> 
             </p>
              <p> At Poll Sphere, we are more than a platform; we are a community of businesses, researchers, and decision-makers. Join us in the pursuit of knowledge, innovation, and success. Explore the endless possibilities that data-driven insights can bring to your organization.</p>
            </div>
          </Col>
          <Col xs={24} md={2}></Col>
          <Col xs={24} md={12}>
            <div className="form-wrapper">
              <Form id="survey_submit_form" layout="vertical">
                <Divider>Connect with Poll Sphere team </Divider>

                <Form.Item name="firstName">
                  <Input placeholder="First Name" />
                </Form.Item>
                <Form.Item name="lastName">
                  <Input placeholder="Last Name" />
                </Form.Item>
                <Form.Item name="email">
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item name="company">
                  <Input placeholder="Company Name" />
                </Form.Item>

                <Form.Item
                  name="dob"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePicker
                    placeholder="Select Appointment  Date "
                    style={{ width: "100%" }}
                  ></DatePicker>
                </Form.Item>

                <Form.Item>
                  <TimePicker
                    defaultOpenValue={dayjs("00:00:00", "HH:mm:ss")}
                  />
                </Form.Item>

                <button className="btn form-btn" htmlType="submit">
                  Submit
                </button>
              </Form>
            </div>
          </Col>
        </Row>
      </div>

      <Segment />

      <HomeAbout />

      <div className="service-wrapper  pt-5">
        <div className="heading-wrapper">
          <h2 className=" heading">Our Services</h2>
        </div>
        <div className="about-service-slide-wrapper">
          <Solutions />
        </div>
      </div>
    </div>
  );
};

export default Home;
